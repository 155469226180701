import React, { useState } from "react";
import { Input } from "../../../../src/components-shadcn/ui/input";
import { Button } from "../../../components-shadcn/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components-shadcn/ui/select";
import { NotebookTabs } from "lucide-react";
import { toast } from "sonner";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";

const AddWithPhoneNumber = ({ setSearchPhone, setAddNewMember }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const { entityDepartments } = useTipJarLocalState();

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{9,12}$/;
    return phoneRegex.test(phone.replace(/\D/g, ""));
  };

  const checkDuplicatePhone = (phone) => {
    const existingMembers = localStorage.getItem("express_members");
    if (existingMembers) {
      const { express_members } = JSON.parse(existingMembers);
      return express_members.some((member) => member.phone === phone);
    }
    return false;
  };

  const updateMemberDepartments = (phoneNumber, departmentId) => {
    // Get existing department mappings
    const existingDepartments = localStorage.getItem("memberDepartments");
    let departmentMappings = {};

    if (existingDepartments) {
      departmentMappings = JSON.parse(existingDepartments);
    }

    // Add new mapping
    departmentMappings[phoneNumber] = departmentId;

    // Save updated mappings
    localStorage.setItem(
      "memberDepartments",
      JSON.stringify(departmentMappings)
    );
  };

  const handleSubmit = () => {
    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    // Check for duplicate phone number
    if (checkDuplicatePhone(phoneNumber)) {
      toast.error("A member with this phone number has already been added");
      return;
    }

    // Validate names
    if (!firstName.trim()) {
      toast.error("First name is required");
      return;
    }

    if (!lastName.trim()) {
      toast.error("Last name is required");
      return;
    }

    // Validate department selection
    if (!selectedDepartment) {
      toast.error("Please select a department");
      return;
    }

    // Create new member object
    const newMember = {
      id: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      department_id: selectedDepartment,
      can_join_entity_jar: true,
      shukran_id: "N/A",
    };

    // Get existing members or initialize empty array
    const existingData = localStorage.getItem("express_members");
    let memberPayload = { express_members: [] };

    if (existingData) {
      memberPayload = JSON.parse(existingData);
    }

    // Add new member to array
    memberPayload.express_members.push(newMember);

    try {
      // Save member payload
      localStorage.setItem("express_members", JSON.stringify(memberPayload));

      // Update department mappings
      updateMemberDepartments(phoneNumber, selectedDepartment);

      // Reset form
      setPhoneNumber("");
      setFirstName("");
      setLastName("");
      setSelectedDepartment("");
      setAddNewMember(false);
    } catch (error) {
      toast.error("Failed to save member details");
    }
  };

  return (
    <div className="flex flex-col w-full max-w-md mx-auto p-4">
      <label className="text-3xl font-semibold text-primary-pwa">
        Add with phone number
      </label>

      <div className="relative mt-6">
        <Input
          type="tel"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="w-full h-[55px] shadow-md border-0 focus:ring-0 rounded-lg mb-4"
        />
        <button className="absolute right-2 top-2 w-10 h-10 rounded-full flex items-center justify-center">
          <NotebookTabs />
        </button>
      </div>

      <Input
        type="text"
        placeholder="First name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="mb-4 h-[55px] shadow-md border-0 focus:ring-0 rounded-lg"
      />

      <Input
        type="text"
        placeholder="Last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="mb-4 h-[55px] shadow-md border-0 focus:ring-0 rounded-lg"
      />

      <Select value={selectedDepartment} onValueChange={setSelectedDepartment}>
        <SelectTrigger className="mb-6 h-[55px] shadow-md border-0 focus:ring-0 rounded-lg">
          <SelectValue placeholder="Select department" />
        </SelectTrigger>
        <SelectContent>
          {entityDepartments.map((dept) => (
            <SelectItem key={dept.id} value={dept.id} disabled={!dept.active}>
              {dept.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div className="py-4 flex flex-row items-center text-sm">
        <span className="text-primary">OR</span>
        <div
          className="border-none rounded-md px-1 py-1 bg-[#64C8CE] text-white ml-2 cursor-pointer"
          onClick={() => setSearchPhone(false)}
        >
          add with Shukran ID
        </div>
      </div>

      <Button
        onClick={handleSubmit}
        className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white h-[60px] rounded-full font-medium"
      >
        PROCEED
      </Button>
    </div>
  );
};

export default AddWithPhoneNumber;
