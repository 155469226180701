import axios from "axios";
import React, { useState } from "react";
import { testUrl } from "../../helper";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { MoonLoader } from "react-spinners";
import {
  getSaccoBalances,
  loadBalanceResults,
} from "../../state/reducers/user/user.thunk";
import { useNavigate } from "react-router-dom";

export default function UpdateAkiba({ setAkibaShow, type, setShowToast }) {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const claimReward = async () => {
    setLoading(true);
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${account.userDetails.token}`;
      axios.defaults.headers.common["XAT"] = `U`;
      axios.defaults.headers.common["X-IDT"] = `A`;
      const res = await axios.post(`${testUrl}sacco/akiba100/redeem/`);

      if (res.data.success) {
        setShowToast({ show: true, type: "success" });
        setAkibaShow(false);
        dispatch(getSaccoBalances(account.userDetails.token));
        //dispatch(loadBalanceResults(account.userDetails.token));
        //dispatch();
      } else {
        setShowToast({ show: true, type: "fail" });
      }
    } catch (e) {
      setLoading(false);
      setShowToast({ show: true, type: "fail" });
    }
  };
  //staging.shukran.co/
  return (
    <div className="px-8 drop-shadow-xl w-11/12 h-3/5 bg-white mt-10 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        onClick={() => setAkibaShow(false)}
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
      >
        X
      </button>
      {/* <img src={inviteSacco} alt="error-img" className="h-28 pb-4" /> */}
      {/* <p className="justify-self-center text-lg text-center py-2">
        Referral
      </p> */}
      {type === "failed" && (
        <div className="flex flex-col items-center justify-center">
          <p
            className="justify-self-center	text-lg text-center py-5"
            style={{ fontSize: "60px" }}
          >
            ☹️
          </p>
          <p className=" justify-self-center text-md text-center py-2 text-black">
            Looks like you lost your savings streak!
          </p>
          <p
            sytle={{ color: "#929FB1", fontSize: "6px" }}
            className=" justify-self-center text-sm text-center py-2"
          >
            You can restart it at any time by saving as little as Ksh.100 daily
          </p>
          <button
            className="rounded-full py-2 bg-accent px-3 text-white text-m mx-auto mt-2 w-11/12"
            onClick={() => {
              setAkibaShow(false);
              // navigate("/activateExplainer");
            }}
            style={{ position: "absolute", bottom: 10 }}
            type="button"
          >
            PROCEED
          </button>
        </div>
      )}
      {type === "success" && (
        <div className="flex flex-col items-center justify-center">
          <p
            className="justify-self-center	text-lg text-center py-5"
            style={{ fontSize: "60px" }}
          >
            🥳
          </p>
          <p className=" justify-self-center text-md text-center py-2 text-black">
            Congratulations for your savings streak!
          </p>
          <p
            sytle={{ color: "#929FB1", fontSize: "6px" }}
            className=" justify-self-center text-sm text-center py-2"
          >
            Claim your weekly reward and keep saving daily for high tier
            rewards.
          </p>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MoonLoader size={25} loading={true} color="#F2C773" />
            </div>
          ) : (
            <button
              className="rounded-full py-2 bg-accent px-3 text-white text-m mx-auto mt-2 w-11/12"
              onClick={() => claimReward()}
              style={{ position: "absolute", bottom: 10 }}
              type="submit"
            >
              CLAIM REWARD
            </button>
          )}

          <ToastContainer />
        </div>
      )}
      {type === "new" && (
        <div className="flex flex-col items-center justify-center">
          <p
            className="justify-self-center	text-lg text-center py-5"
            style={{ fontSize: "60px" }}
          >
            🥳
          </p>
          <p className=" justify-self-center text-md text-center py-2 text-black">
            Start your saving streak today!
          </p>
          <p
            sytle={{ color: "#929FB1", fontSize: "6px" }}
            className=" justify-self-center text-sm text-center py-2"
          >
            Claim your weekly reward and keep saving daily for high tier
            rewards.
          </p>

          <button
            className="rounded-full py-2 bg-accent px-3 text-white text-m mx-auto mt-2 w-11/12"
            onClick={() => {
              setAkibaShow(false);
            }}
            style={{ position: "absolute", bottom: 10 }}
            type="submit"
          >
            PROCEED
          </button>
        </div>
      )}
    </div>
  );
}
