import React, { useState, useEffect } from "react";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, Outlet } from "react-router-dom";
import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import { testUrl, formatPhoneNumber } from "../../helper";

import { saveToSessionStorage } from "../../Services/Storage";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import useIsIOS from "../popups/useIsIos";
import userStore from "../../store/user.store";
import { ToastContainer, toast } from "react-toastify";
import useResetZustandState from "../../../src/state/zustand-state/clear-zustand-state";

const axios = require("axios");

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  // isIOS.prompt
  let onboardingState = localStorage.getItem("hasSeenOnboarding");
  console.log("ON BOARDING STATE ---> ", onboardingState);
  console.log("TEST URL --- ", testUrl);
  const [isIOS, setIsIOS] = useIsIOS();
  const [isError, setIsError] = useState(false);
  const [errorBody, setErrorBody] = useState();
  const [submittedPhone, setSubmittedPhone] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(0);
  const [isClicked, setIsclicked] = useState(false);

  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const dispatch = useDispatch();
  useResetZustandState();

  const { loginUser, registerToken } = bindActionCreators(actions, dispatch);

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, []);

  // values from react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(resetActions.updateProfileError(""));
    let isTermsAccepted = localStorage.getItem("termsAccepted");
    isTermsAccepted = JSON.parse(isTermsAccepted);
    setTermsAccepted(isTermsAccepted);
    return () => {};
  }, []);

  useEffect(() => {
    if (balanceState.errorMessage === 403) {
      setTimeout(() => {
        dispatch(balanceActions.getUserDataError(""));
      }, 3000);
    }
    return () => {};
  }, [balanceState, dispatch]);

  // navigate function
  let navigate = useNavigate();
  // puts state into object
  const getData = (data) => {
    let formattedNo = formatPhoneNumber(data.phonenumber);
    setSubmittedPhone(formattedNo);
    return {
      phone: formattedNo,
      password: data["password"],
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
  };

  // set method that runs when the submit is hit
  const handleFormSubmit = async (data) => {
    let url = `${testUrl}accounts/auth/signin/`;

    try {
      if (data.confirm || termsAccepted) {
        setIsLoading(true);
        localStorage.setItem("termsAccepted", JSON.stringify(true));
        axios.defaults.headers.common["Authorization"] = ``;
        const response = await axios({
          url: `${testUrl}accounts/auth/signin/`,

          headers: {
            "Content-Type": "application/json",
            XAT: "U",
            "X-IDT": "A",
          },
          method: "post",
          data: getData(data),
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        });

        if (response["status"] === 200) {
          let loginRes = response.data.data;

          userStore.getState().setUserInfo(loginRes);

          await saveToSessionStorage("token", loginRes.token);
          await saveToSessionStorage("ShukranId", loginRes.shukran_id);
          await saveToSessionStorage("firstName", loginRes.first_name);
          await saveToSessionStorage("phoneNumber", loginRes.phone);
          loginUser(loginRes);
          dispatch(
            balanceActions.setUserType(loginRes.user_type?.toLowerCase())
          );
          dispatch(
            balanceActions.setSaccoType(loginRes.sacco_type?.toLowerCase())
          );
          dispatch(balanceActions.balanceLoadError(""));
          dispatch(saccoActions.getBalanceError(""));

          setTimeout(() => {
            setIsError(false);
            setIsLoading(false);
            navigate("/");
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("origin", false);
            localStorage.setItem("token", loginRes.token);
          }, 1000);
        } else if (response["status"] > 400 && response["status"] !== 401) {
          setIsError(true);
          setErrorBody(response.data);
          setIsLoading(false);
        } else if (response["status"] === 401) {
          setIsError(true);

          setErrorBody(response.data);
          setIsLoading(false);
        } else {
          setIsError(true);
          setIsLoading(false);
          navigate("/login");
        }
      } else {
        toast.warning("Please agree to terms of use and privacy", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (e) {
      if (e.request) {
        setIsError(true);
        setErrorBody({
          msg: "Kindly check your internet to use Shukran",
        });
        setIsLoading(false);
      }
    }
  };

  const handleConfirmOTP = () => {
    let params = {
      phone: submittedPhone,
      token: errorBody.token,
    };
    registerToken(params);
    // dispatch(requestOtpCode(params, errorBody.token));
    navigate("/confirmcode");
  };

  const handlePasswordToggle = () => {
    setIsclicked(!isClicked);
  };

  useEffect(() => {
    // if (isAuthenticated) navigate("/");
  }, []);

  const LoginError = () => (
    <div className="p-7 drop-shadow-2xl bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50 w-10/12">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setIsError(false);
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20 my-3" />
      <h2 className={"text-center font-bold	text-lg"}>Login Error</h2>
      <p className="justify-self-center	text-base text-center py-2">
        {errorBody?.msg}
      </p>
      {errorBody?.data === "inactive" && (
        <button
          onClick={() => handleConfirmOTP()}
          className="rounded-full bg-[#64C8CE] text-white text-base w-full mt-4 px-10 py-4"
        >
          Confirm OTP
        </button>
      )}
    </div>
  );

  const TokenTimeout = () => (
    <div className="p-7 drop-shadow-2xl bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50 w-10/12">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.getUserDataError(""));
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20 my-3" />
      <h2 className={"text-center font-bold	text-lg"}>Session Timeout</h2>
      <p className="justify-self-center	text-base text-center py-2">
        Oops, your session has expired, kindly log in
      </p>
    </div>
  );

  return (
    <div
      className={`
      ${isLoading ? "relative" : ""}
      ${isError ? "relative" : ""}
      w-full flex flex-col`}
    >
      <div
        className={`
          ${isLoading ? "blur-sm" : ""}
          ${isError ? "blur-sm" : ""}
          flex w-full flex-col text-black mx-auto bg-imag-dash`}
      >
        <div className="header flex flex-row mt-2 justify-center">
          {/* <Link to="/" className="underline"> */}
          <a
            href="https://shukran.co/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            <img
              src={logo}
              alt="logo of shukran"
              className="rounded py-3 h-16 mt-16 mb-4"
            />
          </a>
          {/* </Link> */}
        </div>
        <div className="input-div flex flex-col pt-4 bg-white rounded-t-3xl px-8">
          <h2 className="text-lg">Login to your Shukran account</h2>
          <form className="flex flex-col">
            <div className="flex flex-col mt-2">
              <label className="text-[#929FB1] mt-2 text-sm italic">
                Phone Number{" "}
              </label>
              <input
                className="border-solid border-b-2 focus:border-b-[#50A2A7] placeholder:text-base text-black outline-none h-12 w-full"
                type="number"
                placeholder="0700000000"
                {...register("phonenumber", {
                  required: true,
                  // maxLength: 10,
                  // pattern:
                  //   /^(?:254|\+254|0)?((?:(?:7(?:(?:3[0-9])|([01249][0-9])|(?:5[0-6])|(8[5-9])))|(?:1(?:[1][0-5]))|(?:1(?:[0][0-2]))|(?:2(?:[0][0-9])))[0-9]{6})$/i,
                })}
              />

              {errors.phonenumber && (
                <p className="text-red-600	text-sm">
                  Please enter a valid safaricom number
                </p>
              )}
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-[#929FB1] mt-2 text-sm italic">
                Password{" "}
              </label>
              <div className="flex flex-row items-center">
                <input
                  className="border-solid border-b-2 focus:border-b-[#50A2A7] outline-none placeholder:text-base h-12 text-black w-full"
                  type={isClicked ? "text" : "password"}
                  placeholder={isClicked ? "password123" : "*******"}
                  {...register("password", {
                    required: true,
                  })}
                />
                <div className="-ml-6" onClick={() => handlePasswordToggle()}>
                  {isClicked ? (
                    <img src={eye} alt="eye" className="h-6" />
                  ) : (
                    <img src={eyeClosed} alt="eye" className="h-6" />
                  )}
                </div>
              </div>

              {errors.password && (
                <p className="text-red-600	text-sm">
                  Please fill in your password
                </p>
              )}
              {!termsAccepted && (
                <>
                  <div className="flex flex-row items-center mt-5">
                    <input
                      className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-5 w-4 py-2 text-black placeholder:text-base"
                      type={"checkbox"}
                      required={true}
                      value={acceptTerms}
                      onClick={() => {
                        if (acceptTerms === 0) {
                          setAcceptTerms(1);
                        } else if (acceptTerms === 1) {
                          setAcceptTerms(0);
                        }
                      }}
                      {...register("confirm")}
                    />

                    <div
                      className="ml-4"
                      // onClick={() => {
                      //   window.open(
                      //     "https://www.shukransacco.com/privacy/",
                      //     "_blank"
                      //   );
                      //   window.open(
                      //     "https://www.shukransacco.com/terms/",
                      //     "_blank"
                      //   );
                      // }}
                    >
                      <p style={{ fontSize: "14px" }}>
                        I agree to{" "}
                        <a
                          style={{ textDecoration: "underline" }}
                          href="https://shukran.co/terms/"
                          target="_blank"
                        >
                          terms of use
                        </a>{" "}
                        and{" "}
                        <a
                          style={{ textDecoration: "underline" }}
                          href="https://shukran.co/policy/"
                          target="_blank"
                        >
                          privacy policy
                        </a>
                      </p>
                    </div>
                  </div>
                  {errors.confirm && (
                    <p className="text-red-600	text-sm mt-2">
                      Agree to terms and condition
                    </p>
                  )}
                </>
              )}
            </div>

            <div className="flex flex-col mt-5">
              <div className="text-xs text-[#767676]">
                <Link to="/password/forgot" className="italic underline">
                  Forgot Pin? Reset
                </Link>
              </div>
              {/* <div className="flex flex-row mt-6 text-sm items-center ">
                <input
                  type="checkbox"
                  className="mr-2 active:bg-primary-pwa"
                  value="checked"
                  onChange={() => {
                    keepLoggedIn();
                  }}
                />
                <p>Keep me logged in</p>
              </div> */}

              <button
                className="rounded-full bg-[#F2C773] p-2 text-white text-base w-full mx-auto mt-16"
                type="submit" // onClick={() => handleFormSubmit(e)}
                onClick={handleSubmit(handleFormSubmit)}
              >
                SIGN IN
              </button>

              <div className="footer-reg flex flex-col mt-12">
                <p>
                  <Link to="/registration" className="underline">
                    Don't have an account?
                  </Link>{" "}
                  <Link
                    to="/registration"
                    className="underline ml-2 text-[#6CCBD1]"
                  >
                    Register
                  </Link>
                  <Outlet />
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`${
          isError ? "absolute blur-none" : "hidden"
        } flex justify-center items-center flex-col w-full h-full`}
      >
        <LoginError />
      </div>

      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <ToastContainer />
    </div>
  );
}
