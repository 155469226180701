import React, { useEffect } from "react";
import { useState } from "react";
import add from "../../../assets/Icons/tipjar/add.png";
import { Loader2, ChevronRight } from "lucide-react";
import { toast } from "sonner";
import { Button } from "../../../components-shadcn/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components-shadcn/ui/dialog";
import { Input } from "../../../components-shadcn/ui/input";
import { Checkbox } from "../../../components-shadcn/ui/checkbox";
import { AlertCircle, UserX, CircleMinus, Pencil } from "lucide-react";
import BackButton from "../../globals/BackButton";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { PackageOpen } from "lucide-react";
import { useGetJars } from "../../TipGroups/state/server-state/useTipGroupsServerState";
import { useAddMemeberToGroup } from "../state/server-state/useTipJarServerState";
import { useUpdateEntityJar } from "../state/server-state/useTipJarServerState";
import AddButton from "../../globals/AddButton";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components-shadcn/ui/accordion";
import chevron from "../../../assets/Icons/tipjar/chevron.svg";

import { useRemoveMemberFromGroup } from "../state/server-state/useTipJarServerState";
import {
  useDeactivateEntityJar,
  useActivateEntityJAR,
} from "../state/server-state/useTipJarServerState";

const TipJarGroup = () => {
  const [groups, setGroups] = useState([]);
  const { activeEntity, activeEntityJAR, setActiveEntityJAR } =
    useTipJarLocalState();
  const [groupManager, setGroupManager] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [membersWithDepartments, setMembersWithDepartments] = useState([]);

  const updateEntityJar = useUpdateEntityJar();
  const isUpdatingEntityJar = updateEntityJar.isLoading;

  const addGroupMembers = useAddMemeberToGroup();
  const isAddingGroupMembers = addGroupMembers.isLoading;

  const deactivateEntityJar = useDeactivateEntityJar();
  const isDeactivatingEntityJar = deactivateEntityJar.isLoading;

  const activateEntityJar = useActivateEntityJAR();
  const isActivatingEntityJar = activateEntityJar.isLoading;

  const navigate = useNavigate();

  const {
    data: entityJars,
    isLoading: isLoadingEntityJars,
    error: fetchEntityJarError,
    refetch,
  } = useGetJars(activeEntity?.id);

  if (entityJars === 403) {
    navigate("/login/");
  }

  useEffect(() => {
    if (entityJars) {
      const currentEntityJAR = entityJars.data?.data?.find(
        (entityJar) => entityJar.id === activeEntityJAR?.id
      );
      if (currentEntityJAR) {
        setActiveEntityJAR(currentEntityJAR);
      }
    }
  }, [entityJars]);

  const handleDeactivateEntityJar = async () => {
    if (activeEntityJAR?.jar_balance > 0) {
      toast.error("Cannot deactivate entity jar with balance");
      return;
    }
    try {
      await deactivateEntityJar.mutateAsync({
        entity_jar_id: activeEntityJAR.id,
      });
      refetch();
      toast.success("Entity JAR deactivated successfully");
    } catch (error) {
      toast.error("Failed to deactivate entity jar");
    }
  };

  const handleActivateEntityJar = async () => {
    try {
      await activateEntityJar.mutateAsync({
        entity_jar_id: activeEntityJAR.id,
      });
      refetch();
      toast.success("Entity JAR activated successfully");
    } catch (error) {
      toast.error("Failed to activate entity jar");
    }
  };

  const handleUpdateEntityJar = async () => {
    if (!activeEntityJAR || !activeEntityJAR.name) {
      return;
    }

    const payload = {
      name: activeEntityJAR?.name,
      is_default_entity_jar: true,
      entity_jar_id: activeEntityJAR.id,
    };

    try {
      await updateEntityJar.mutateAsync(payload);
      refetch();
    } catch (error) {
      toast.error("Failed update entity jar");
    }
  };

  useEffect(() => {
    if (activeEntityJAR && activeEntityJAR.members && activeEntityJAR.groups) {
      const membersWithDepts = activeEntityJAR.members.map((member) => {
        const group = activeEntityJAR.groups.find((g) => g.id === member.group);
        return {
          ...member,
          departmentId: group ? group.department : null,
        };
      });
      setMembersWithDepartments(membersWithDepts);

      const manager = membersWithDepts.find((member) => member.is_team_leader);
      setGroupManager(manager);
    }
  }, [activeEntityJAR]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleNavigateToGroupDistributionPoints = () => {
    navigate("/tipjar/confirm-distribution-points");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectMember = (member) => {
    setSelectedMember(member);
  };

  const handleConfirm = async () => {
    if (selectedMember) {
      const payload = {
        entity_jar: activeEntityJAR.id,
        shukran_ids: [
          {
            shukran_id: selectedMember.user.shukran_id,
            department_id: selectedMember.departmentId,
            is_team_leader: true,
          },
        ],
      };

      try {
        const response = await addGroupMembers.mutateAsync(payload);
        setGroupManager(selectedMember);
        refetch();
        toast.success("Group manager updated succesfully");
      } catch (error) {
        toast.error("Failed to update group manager");
      }
      setIsDialogOpen(false);
      setSelectedMember(null);
      setSearchTerm("");
    }
  };

  const filteredMembers =
    membersWithDepartments.filter((member) =>
      `${member.user.first_name} ${member.user.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) || [];

  const GroupAvatar = ({ name }) => (
    <div className="w-[30px] h-[30px] rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-3"></div>
  );

  const GroupMemberItem = ({ group }) => {
    const [memberToRemove, setMemberToRemove] = useState("");
    const [membersInGroup, setMembersInGroup] = useState([]);
    const removeGroupMembers = useRemoveMemberFromGroup();
    const isRemovingMemberFromGroup = removeGroupMembers.isLoading;

    const handleRemoveMemberFromGroup = async (payload) => {
      try {
        const response = await removeGroupMembers.mutateAsync(payload);
        refetch();
        toast.success("Members removed successfully");
      } catch (error) {
        toast.error("Failed to remove members");
      }
    };

    const handleRemoveMember = (member) => {
      if (!activeEntityJAR) {
        toast.error("Some data is missing");
        return;
      }
      setMemberToRemove(member);
      const shukranId = member?.user?.shukran_id;
      const payload = {
        entity_jar: activeEntityJAR.id,
        shukran_ids: [shukranId],
      };
      handleRemoveMemberFromGroup(payload);
    };

    const groupId = group.id;
    useEffect(() => {
      const membersInGroup =
        activeEntityJAR.members.filter((member) => member.group === groupId) ||
        [];
      setMembersInGroup(membersInGroup);
    }, [group.id, groupId]);

    return (
      <div className="w-full flex flex-col gap-1 items-end">
        {membersInGroup.map((member, idx) => (
          <div
            className="w-full flex flex-row items-center justify-between border-slate-200 border-b p-2"
            key={idx}
          >
            <div className="w-fit flex flex-col gap-1">
              <p>{`${member.user.first_name} ${member.user.last_name}`}</p>
              <p>{`${member.user.shukran_id}`}</p>
            </div>
            <div
              className="p-2 hover:bg-slate-100 rounded"
              onClick={() => handleRemoveMember(member)}
            >
              {isRemovingMemberFromGroup && memberToRemove.id === member.id ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <CircleMinus size={20} color="red" />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>

      <div className="w-full flex flex-row items-center justify-between">
        <h1 className="text-2xl font-semibold text-[#64C8CE] mb-4 mt-6">
          {activeEntityJAR.name}
        </h1>

        <Button
          className={`flex flex-row items-center justify-center p-1 rounded cursor-pointer ${
            activeEntityJAR.active ? "bg-red-500" : "bg-[#64C8CE]"
          }`}
          onClick={
            activeEntityJAR.active
              ? handleDeactivateEntityJar
              : handleActivateEntityJar
          }
          disabled={isDeactivatingEntityJar || isActivatingEntityJar}
        >
          {isDeactivatingEntityJar || isActivatingEntityJar ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : null}
          <p className="mr-2 text-sm text-white">
            {activeEntityJAR.active ? "Deactivate" : "Activate"}
          </p>
        </Button>
      </div>

      <div className="bg-[#E6F7F8] rounded-lg p-4 mb-6 w-full flex flex-col items-center">
        <p className="text-sm text-gray-600 mb-1">Current Balance</p>
        <p className="text-3xl font-bold text-[#64C8CE]">
          KSH {activeEntityJAR?.jar_balance?.toLocaleString("en-US") || 0}
        </p>
        <Button
          variant="custom"
          disabled={isUpdatingEntityJar || !activeEntityJAR.active}
          className="bg-[#F2C773] text-white text-xs py-1 px-1 rounded-md flex items-center h-[30px] mt-2 hidden"
          onClick={() => handleUpdateEntityJar()}
        >
          {isUpdatingEntityJar && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Make default
        </Button>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h5 className="font-semibold">Group manager</h5>

          <Button
            variant="custom"
            className="bg-[#F2C773] text-white text-xs py-1 px-1 rounded-md flex items-center h-[30px]"
            onClick={() => setIsDialogOpen(true)}
            disabled={!activeEntityJAR.active}
          >
            Change
            <Pencil className="ml-1" color="white" size={15} />
          </Button>
        </div>
        <div className="flex items-center bg-[#EBF9FA] border border-[#6CCBD1] rounded-lg p-2 h-[60px]">
          {!activeEntityJAR.groups || activeEntityJAR.groups.length === 0 ? (
            <div className="flex items-center text-gray-500">
              <AlertCircle className="mr-2" />
              <p className="text-sm">No groups available</p>
            </div>
          ) : groupManager ? (
            <>
              <div className="w-8 h-8 bg-[#50A2A7] rounded-full mr-2 flex items-center justify-center text-white">
                {groupManager.user.first_name[0]}
                {groupManager.user.last_name[0]}
              </div>
              <p className="text-sm">{`${groupManager.user.first_name} ${groupManager.user.last_name}`}</p>
            </>
          ) : (
            <div className="flex items-center text-gray-500">
              <UserX className="mr-2" />
              <p className="text-sm">No group manager assigned</p>
            </div>
          )}
        </div>
      </div>

      <div className="mb-6">
        {activeEntityJAR?.active === true && (
          <div className="flex items-center justify-between w-full mt-6">
            <h5 className="font-semibold">Teams and individuals</h5>
            <div
              className="flex flex-row bg-[#64C8CE] items-center justify-center p-1 rounded cursor-pointer"
              onClick={() => navigate("/tipjar/select-members-in-department/")}
            >
              <p className="mr-2 text-sm text-white">Edit team</p>
              <Pencil className="mr-1" color="white" size={15} />
            </div>
          </div>
        )}

        {!activeEntityJAR.groups || activeEntityJAR.groups.length === 0 ? (
          <div className="w-full flex items-center justify-center mt-12">
            {activeEntityJAR?.active === true ? (
              <AddButton
                title="Add members"
                onClick={() =>
                  navigate("/tipjar/select-members-in-department/")
                }
              />
            ) : (
              <div className="w-full flex flex-col items-center justify-center gap-3">
                <AlertCircle className="mr-2" />
                <p className="text-sm">Tip jar is deactivated</p>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-4">
            <Accordion type="single" collapsible className="space-y-2">
              {activeEntityJAR.groups.map((group, index) => (
                <AccordionItem
                  value={group}
                  key={index}
                  className="border rounded-lg mb-2 overflow-hidden"
                >
                  <AccordionTrigger className="hover:no-underline p-3">
                    <div className="flex items-center justify-between w-full p-1">
                      <div className="flex items-center">
                        <GroupAvatar name={group.name} />
                        <div>
                          <p className="text-sm font-medium text-gray-900">
                            {group.name}
                          </p>
                          {/* <p className="text-xs text-gray-500">
                            {group.members?.length || 0} members
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 py-2">
                    <GroupMemberItem group={group} />
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>

      <div className="mt-auto p-2">
        <Button
          className="w-full bg-[#F2C773] text-white h-[60px] rounded-full font-semibold"
          onClick={() => handleNavigateToGroupDistributionPoints()}
          disabled={activeEntityJAR?.active === false}
        >
          DISTRIBUTE TIPS
        </Button>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Select Group Manager</DialogTitle>
          </DialogHeader>
          <Input
            placeholder="Search members"
            value={searchTerm}
            onChange={handleSearch}
            className="mb-4"
          />
          <div className="space-y-2 max-h-60 overflow-y-auto">
            {filteredMembers.slice(0, 5).map((member) => (
              <div key={member.id} className="flex items-center space-x-2">
                <Checkbox
                  checked={selectedMember?.id === member.id}
                  onCheckedChange={() => handleSelectMember(member)}
                />
                <div className="flex items-center">
                  <div className="w-8 h-8 bg-[#50A2A7] rounded-full mr-2 flex items-center justify-center text-white">
                    {member.user.first_name[0]}
                    {member.user.last_name[0]}
                  </div>
                  <div>
                    <span>{`${member.user.first_name} ${member.user.last_name}`}</span>
                    {/* <p className="text-xs text-gray-500">
                      Dept ID: {member.departmentId}
                    </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {selectedMember && (
            <div className="mt-4 p-2 bg-[#EBF9FA] border border-[#6CCBD1] rounded-lg">
              <div className="flex items-center">
                <div className="w-8 h-8 bg-[#50A2A7] rounded-full mr-2 flex items-center justify-center text-white">
                  {selectedMember.user.first_name[0]}
                  {selectedMember.user.last_name[0]}
                </div>
                <div>
                  <span>{`${selectedMember.user.first_name} ${selectedMember.user.last_name}`}</span>
                  {/* <p className="text-xs text-gray-500">Dept ID: {selectedMember.departmentId}</p> */}
                </div>
              </div>
            </div>
          )}

          <Button
            className="w-full mt-4 bg-[#F2C773] text-white rounded-md"
            onClick={handleConfirm}
            disabled={isAddingGroupMembers}
          >
            {isAddingGroupMembers && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Confirm
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TipJarGroup;
